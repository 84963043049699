import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './footer.css';
import logo from '../../../static/images/logo.png';
import weChat from '../../../static/images/WeChat.jpg';
import badge from '../../../static/images/badge.png';

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer mt-auto pt-5">
          <div className="container">
              <div className="img-container text-center">
                <img src={logo} alt="" className="logo img-fluid"/>
                <img src={weChat} alt="" className="qrcode img-fluid" />
              </div>
            <div className="row text-center">
              <div className="col-md-2 xs-6">
                <ul className="footer-nav">
                  <li><Link to="/frontend/about/vision/index.html">关于我们</Link></li>
                  <li><Link to="/frontend/about/vision/index.html">我们的愿景</Link></li>
                  <li><Link to="/frontend/about/progress/index.html">发展历程</Link></li>
                  <li><Link to="/frontend/about/leadership/index.html">核心团队</Link></li>
                  <li><Link to="/frontend/about/partner/index.html">合作伙伴</Link></li>
                </ul>
              </div>
              <div className="col-md-2 xs-6">
                <ul className="footer-nav">
                  <li><a href="/frontend/business/market/index.html">我们的业务</a></li>
                  <li><a href="/frontend/business/market/index.html">面向的市场</a></li>
                  <li><a href="/frontend/business/types/index.html">业务模式</a></li>
                  <li><a href="/frontend/business/classicCase/index.html">经典案例</a></li>
                </ul>
              </div>
              <div className="col-md-2 xs-6">
                <ul className="footer-nav">
                  <li><a href="/frontend/product/list/index.html">我们的产品</a></li>
                </ul>
              </div>
              <div className="col-md-2 xs-6">
                <ul className="footer-nav">
                  <li><Link to="/frontend/updates/articles/index.html">观点&更新</Link></li>
                  <li><Link to="/frontend/updates/articles/index.html">维石观点</Link></li>
                  <li><Link to="/frontend/updates/news/index.html">最近新闻</Link></li>
                  {/* <li><Link to="/updates/news">下载服务</Link></li> */}
                  <li><Link to="/frontend/Q&A">Q&A</Link></li>
                </ul>
              </div>
              <div className="col-md-2 xs-6">
                <ul className="footer-nav">
                  <li><a href="/frontend/contact/index.html">联系我们</a></li>
                </ul>
              </div>
              <div className="col-md-2 xs-6">
                <ul className="footer-nav">
                  <li><a href="/frontend/about/affiliates/index.html">关联公司</a></li>
                </ul>
              </div>
            </div>
            <div className="registration-info">
              Copyright © 2019 维石Livision整体卫浴 版权所有
              <span className="newline"></span> 	
              &nbsp;&nbsp;<img src={badge} alt="" />
              京ICP备17025778号
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;