import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import './classic_case.css'
import vision_1 from '../../../static/images/vision-1.png';


class ClassicCase extends Component {
  state = {
    currentPage: {
      parent: '我们的业务',
      child: '经典案例'
    },
    caseList: [{
      id: 1,
      name: '朝青知筑创客户型',
      city: '北京',
      type: '住宅',
      work: '样板间整体卫浴',
      status: '新建'
    }, {
      id: 2,
      name: '深圳裕璟幸福家园',
      city: '深圳',
      type: '住宅',
      work: '样板间及工法间',
      status: '新建'
    }, {
      id: 3,
      name: '连云港西交科技园人才公寓',
      city: '连云港',
      type: '公寓',
      work: '整体卫浴供应',
      status: '新建'
    }, {
      id: 4,
      name: '北京太和祥养老中心',
      city: '北京',
      type: '养老',
      work: '适老化改造',
      status: '既有改造'
    }, {
      id: 5,
      name: '澳中企业博士楼改造',
      city: '东莞',
      type: '公寓',
      work: '整体卫浴供应',
      status: '既有改造'
    }, {
      id: 6,
      name: '保利紫山',
      city: '佛山',
      type: '住宅',
      work: '实体样板间',
      status: '新建'
    }, {
      id: 7,
      name: '同济大学',
      city: '上海',
      type: '住宅',
      work: '整体卫浴研究技术咨询',
      status: '技术咨询'
    }]
  }
  componentDidMount() {
    ScrollReveal().reveal('.case_head_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />

        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                经典案例 <small class="text-muted">Our Portfolio</small>
              </h2>
            </div>  
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_1} alt="" className="img-fluid lg vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div
          className="classicCase_content container"
          style={{ marginBottom: '50px' }}
        >
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">案例名称</th>
                <th scope="col">所在城市</th>
                <th scope="col">类型</th>
                <th scope="col">案例内容</th>
                <th scope="col">案例性质</th>
              </tr>
            </thead>
            <tbody>
              {this.state.caseList.map(item => (
                <tr>
                  <th scope="row">{item.name}</th>
                  <td>{item.city}</td>
                  <td>{item.type}</td>
                  <td>{item.work}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ClassicCase
