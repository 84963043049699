import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './header.css'
import logo from '../../../static/images/logo.png'
import $ from 'jquery'
import _ from 'lodash'

class Header extends Component {
  static propTypes = {
    currentPage: PropTypes.object.isRequired
  }

  state = {
    router: [
      {
        page: '关于我们',
        haveChild: true,
        active: false,
        floatLeft: true,
        child: [
          {
            page: '我们的愿景',
            path: '/frontend/about/vision/index.html',
            active: false
          },
          {
            page: '发展历程',
            path: '/frontend/about/progress/index.html',
            active: false
          },
          {
            page: '我们的工厂',
            path: '/frontend/about/factories/index.html',
            active: false
          },
          {
            page: '核心团队',
            path: '/frontend/about/leadership/index.html',
            active: false
          },
          {
            page: '合作伙伴',
            path: '/frontend/about/partner/index.html',
            active: false
          }
          // {
          //   page: '相关企业',
          //   path: '/about/affiliates',
          //   active: false
          // }
        ]
      },
      {
        page: '我们的业务',
        haveChild: true,
        floatLeft: true,
        active: false,
        child: [
          {
            page: '面向的市场',
            path: '/frontend/business/market/index.html',
            active: false
          },
          {
            page: '业务模式',
            path: '/frontend/business/types/index.html',
            active: false
          },
          {
            page: '经典案例',
            path: '/frontend/business/classicCase/index.html',
            active: false
          }
        ]
      },
      {
        page: '我们的产品',
        haveChild: false,
        active: false,
        floatLeft: true,
        path: '/frontend/product/list/index.html',
        child: []
      },
      // {
      //   page: '服务支持',
      //   haveChild: false,
      //   active: false,
      //   floatLeft: true,
      //   path: '/support',
      //   child: []
      // },
      {
        page: '新闻观点',
        haveChild: true,
        active: false,
        floatLeft: true,
        child: [
          {
            page: '维石理念',
            path: '/frontend/updates/articles/index.html',
            active: false
          },
          {
            page: '最近新闻',
            path: '/frontend/updates/news/index.html',
            active: false
          }
        ]
      },
      {
        page: '联系我们',
        haveChild: false,
        active: false,
        floatLeft: false,
        path: '/frontend/contact/index.html',
        child: []
      },
      {
        page: '加入维石',
        haveChild: false,
        active: false,
        floatLeft: false,
        path: '/frontend/join/index.html',
        child: []
      },
      {
        page: 'Q&A',
        haveChild: false,
        active: false,
        floatLeft: false,
        path: '/frontend/Q&A/index.html',
        child: []
      }
      // ,
      // {
      //   page: '注册会员',
      //   haveChild: false,
      //   active: false,
      //   floatLeft: false,
      //   path: '/register',
      //   child: []
      // }
    ],
    navShow: false,
    childNavShow: false,
    selectedPage: '',
    magicLineLeft: '-480px',
    activeLinkWidth: ''
  }

  componentDidMount() {
    let activeA
    for (let i = 0; i < $('.navbar .nav-link').length; i++) {
      if (
        $('.navbar .nav-link')
          .eq(i)
          .text() === this.props.currentPage.parent
      ) {
        activeA = $('.navbar .nav-link').eq(i)
        break
      }
    }
    this.state.router.forEach(item => {
      if (item.page === this.props.currentPage.parent) {
        item.child.forEach(childItem => {
          if (childItem.page === this.props.currentPage.child) {
            childItem.active = true
          }
        })
      }
    })
    this.setState({
      router: this.state.router
    })

    this.setLinePosition(activeA)

    $(window).resize(_.debounce(() => {
      this.setLinePosition(activeA)
    }, 200))
  }

  setLinePosition = (active) => {
    if (active) {
      $('.magic-line').css({
        left: active.position().left,
        width: active.innerWidth()
      })
      this.setState({
        magicLineLeft: active.position().left,
        activeLinkWidth: active.innerWidth()
      })
    }
  }

  navChange = () => {
    this.setState(
      {
        navShow: !this.state.navShow
      })
  }

  childNavChange = (e, page) => {
    this.setState({
      selectedPage: this.state.selectedPage === page.page ? '' : page.page
    })
  }

  navHover = e => {
    if ($(window).width() < 1260) {
      return
    }
    let offsetLeft
    let width = '80px'
    if (e.target.tagName === 'A') {
      if (
        $(e.target)
          .parent()
          .parent('ul')
          .attr('class')
          .indexOf('child-nav') > 0
      ) {
        return
      }
      width = $(e.target).innerWidth()
      offsetLeft = $(e.target).position().left
    } else if (e.target.tagName === 'LI') {
      width = $(e.target)
        .children('a')
        .eq(0)
        .innerWidth()
    }
    $('.magic-line').css({
      left: offsetLeft,
      width
    })
  }

  navLeave = e => {
    $('.magic-line').css({
      width: this.state.activeLinkWidth,
      left: this.state.magicLineLeft
    })
  }

  render() {
    return (
      <div className="header" onMouseLeave={this.navLeave}>
        <div className="header-container">
          <nav className="navbar navbar-expand navbar-light">
            <a className="navbar-brand" href="/frontend/home/index.html">
              <img src={logo} alt="logo" className="img-fluid" />
            </a>

            {this.state.navShow ? (
              <span className="icon-cha" onClick={this.navChange}>
                +
              </span>
            ) : (
              <span className="icon-nav" onClick={this.navChange}>
                <span className="line" />
              </span>
            )}
            <div
              className={`navbar-collapse ${
                this.state.navShow ? 'show-nav' : ''
              }`}
            >
              <ul className="navbar-nav mr-auto">
                {this.state.router.map(page =>
                  page.floatLeft ? (
                    <li
                      key={page.page}
                      className={`nav-item have-child ${
                        page.active ? 'active' : ''
                      }`}
                      onMouseMove={e => this.navHover(e)}
                    >
                      <a
                        className={`nav-link ${page.haveChild ? 'bold' : ''} ${
                          this.state.selectedPage ? 'light' : ''
                        }`}
                        href={`${
                          page.haveChild ? 'javascript:void(0)' : page.path
                        }`}
                        onClick={e => this.childNavChange(e, page)}
                      >
                        {page.page}
                      </a>
                      {page.child.length > 0 ? (
                        <div className="dropdown shadow-sm">
                          <ul
                            className={`container child-nav ${
                              this.state.selectedPage === page.page
                                ? 'show'
                                : ''
                            }`}
                            onMouseLeave={this.navLeave}
                          >
                            {page.child.map(childPage => (
                              <li key={childPage.page}>
                                <Link
                                  className={`nav-link ${
                                    childPage.active ? 'active' : ''
                                  }`}
                                  to={childPage.path}
                                >
                                  {childPage.page}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        ''
                      )}
                    </li>
                  ) : (
                    ''
                  )
                )}
              </ul>
              <ul className="navbar-nav">
                {this.state.router.map(page =>
                  page.floatLeft ? (
                    ''
                  ) : (
                    <li
                      key={page.page}
                      className={`nav-item have-child ${
                        page.active ? 'active' : ''
                      }`}
                      onMouseMove={e => this.navHover(e)}
                    >
                      <Link
                        className={`nav-link ${page.haveChild ? 'bold' : ''} ${
                          this.state.selectedPage ? 'light' : ''
                        }`}
                        to={page.path}
                        onClick={e => this.childNavChange(e, page)}
                      >
                        {page.page}
                      </Link>
                      {page.child.length > 0 ? (
                        <div className="dropdown shadow-sm">
                          <ul
                            className={`container child-nav ${
                              this.state.selectedPage === page.page
                                ? 'show'
                                : ''
                            }`}
                            onMouseLeave={this.navLeave}
                          >
                            {page.child.map(childPage => (
                              <li key={childPage.page}>
                                <Link
                                  className={`nav-link ${
                                    childPage.active ? 'active' : ''
                                  }`}
                                  to={childPage.path}
                                >
                                  {childPage.page}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        ''
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </nav>
          <span className="magic-line" />
        </div>
      </div>
    )
  }
}

export default Header
