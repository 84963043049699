import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import Button from '../../components/button/button'
import ScrollReveal from 'scrollreveal'

import market_1 from '../../../static/images/market-1.jpg';
import market_2 from '../../../static/images/market-2.jpg';
import market_3 from '../../../static/images/market-3.jpg';
import market_4 from '../../../static/images/market-4.jpg';
import market_5 from '../../../static/images/market-5.jpg';
import market_6 from '../../../static/images/market-6.jpg';


class Market extends Component {
  state = {
    currentPage: {
      parent: '我们的业务',
      child: '面向的市场'
    }
  }
  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
    ScrollReveal().reveal('.vision_img_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                我们服务的市场 <small class="text-muted">Markets we serve</small>
              </h2>
              <p>
                维石全定制整体卫浴区别于传统整体卫浴固定尺寸、单一选择，我们利用专利柔性制造技术，率先实现空间和功能的定制化，不浪费宝贵的套内面积。同时，
                提供更为丰富的部件和材质选择（SMC／彩钢板／瓷砖／大理石），让整体卫浴真正进入了定制化时代。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={market_1} alt="" className="img-fluid vision_img_transition"/>
        </div>
        
        <div className="container vision-container">
          <Button url={'/frontend/business/classicCase/index.html'} buttonName={'经典案例'} /> &nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="mt-2">
                住宅 
              </h3>
              <p>
                针对中国家庭市场，开发出以彩钢板、瓷砖、大理石为主导的中、高端产品，满足家庭用户对功能与审美的双重需求。
              </p>
              <ul>
                <li>普通住宅</li>
                <li>别墅</li>
              </ul>
            </div>
          </div>
        </div>  

        <div className="vision-img-container">
          <img src={market_2} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="mt-2">
              公寓
              </h3>
              <p>
                维石公寓定制整体卫浴，利用柔性制造技术，极致利用空间，具有节能环保、可视化快装、耐用20年、一站式维护等特点，是实现经营者与使用者双赢的高性价比之选。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={market_3} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>


        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="mt-2">
              学校 / 员工宿舍
              </h3>
              <p>
              现代化工厂生产的维石整体卫浴，能实现如厕、盥洗、清洁等多个模块的组合与拆分，充分考虑学校 / 员工宿舍等集体空间的实际需要，
              创造整洁、有序的集体环境。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={market_4} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="mt-2">
              酒店
              </h3>
              <p>
              为中高端酒店专项定制从前期设计、生产安装到后期维护的全流程解决方案，大幅提升卫浴使用体验，给入住客户更为贴心的关怀。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={market_5} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="mt-2">
              适老
              </h3>
              <p>
              为适老项目提供整体卫浴解决方案、产品及服务，我们帮助正在逐步步入老年，身体机能退化的老年群体，保持独立、有尊严生活的能力。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={market_6} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <Footer />
      </div>
    )
  }
}

export default Market
