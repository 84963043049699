import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import './vision.css'
import vision_1 from '../../../static/images/vision-1.jpg';
import vision_9 from '../../../static/images/vision-9.png';
import vision_10 from '../../../static/images/vision-10.jpg';
import vision_11 from '../../../static/images/vision-11.jpg';
import Button from '../../components/button/button'

class Vision extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '我们的愿景'
    }
  }
  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
    ScrollReveal().reveal('.vision_img_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                公司简介 <small className="text-muted">Company profile</small>
              </h2>
              <p>
                北京维石住工科技有限公司，成立于2017年，是国内整体卫浴和装配式内装相关行业标准的主要参编制定者。
              </p>
              <p>
                维石团队将十五年的行业经验与制造、设计、工程和数字化领域最具开创性的新技术相结合，解决了困扰行业多年的定制化技术难题。维石全定制整体卫浴生产线，获批“国家重点研发计划项目科技示范工程”，开创中国”全定制整体卫浴“先河。
              </p>
              <p>
                维石采用先进的装配式生产模式替代传统住宅装修落后的现场作业施工模式，用强大的数字化技术将一切连接在一起，将工厂变为建筑装修工地的延伸，实现工厂与工地一体化，为用户提供更具价值的端到端装配式内装系统解决方案、产品与服务。
              </p>
            </div>
          </div>
        </div>
        <div className="container vision-container ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                愿景及使命 <small className="text-muted">Our vision & mission</small>
              </h2>
              <p>
                在大部分行业已经跨越电气化、互联网化，即将进入工业4.0云时代的时候，中国住宅卫生间的装修方式还徘徊在依靠人力的手工业时代里，建筑装修公司仅仅将不到1%的收入投资于科研，远低于其他主要行业。事实表明，卫生间装修采用“手工作业、现场施工”的生产方式，普遍存在成本不断攀升、施工周期长、施工环境差、质量不可控、污染严重等问题，卫生间在使用过程中的“堵、漏、臭、吵”等顽疾也无法根治，中国数万亿级的建筑装修业，已经到了必须要变革的时候了。
              </p>
              <p>
                维石的使命是创造更加舒适、清洁的家庭水空间，推动整体卫浴这一更加先进的生产方式，真正进入中国每个家庭。
              </p>
              <p>
                为此，我们的团队依托近20年的行业经验，以自我革命的决心，潜心研发，实现行业多项重大技术革新，开启中国数字化 “全定制整体卫浴”先河。用 “工厂生产、现场组装”的整体卫浴替代传统卫生间落后的装修方式，秦皇岛维石全定制整体卫浴生产线，荣获“国家重点研发计划项目科技示范工程”殊荣。
              </p>
              <p>
                我们参与制定了多项行业标准，始终坚守更高的技术和美学标准，生产高品质的、可持续的、健康和耐用的定制化整体卫浴，为住宅、公寓、宾馆等工程项目和个人用户提供装配式卫浴空间系统解决方案、产品与服务。
              </p>
              <p>
                欢迎您进一步了解有关维石“全定制整体卫浴“的更多信息。
              </p>
            </div>
            <div>
              <Button url={'/frontend/about/leadership/index.html'} buttonName={'我们的团队'} /> &nbsp;&nbsp;&nbsp;&nbsp;
              <Button url={'/frontend/business/types/index.html'} buttonName={'服务'} />
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_1} alt="" className="img-fluid lg vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h2>我们的方法是不同的 <small className="text-muted">Our approach are different</small></h2>
              <p>
                不同于传统装修行业，我们用工业化取代了现场手工作业方式，像制造汽车一样在工厂“制造”整体卫浴，不仅保证了卫生间的质量，也极大简化了从建材生产商到消费者家里的距离，这种改变，让整个流程变得简单、易把控。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_9} alt="" className="img-fluid vision_img_transition" style={{margin: '35px 0'}}/>
        </div>
        
        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <p>
                不仅如此，作为行业革新者，我们坚持用持续创新解决行业问题，维石4大技术创新、7大体系升级，让整体卫浴与住宅更契合，安装更简单，质量更可靠，体验更舒适。
              </p>
            </div>
            <img src={vision_10} alt="" className="img-fluid vision_img_transition" style={{height: 'auto'}}/>
            <img src={vision_11} alt="" className="img-fluid vision_img_transition" style={{height: 'auto'}}/>
          </div>
        </div>

        <hr className="featurette-divider"></hr>

        <Footer />
      </div>




  
    )
  }
}

export default Vision
