import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import './partner.css'
import partner_1 from '../../../static/images/partner-1.png';
import partner_2 from '../../../static/images/partner-2.png';

class Partner extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '合作伙伴'
    }
  }

  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
    ScrollReveal().reveal('.vision_img_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
          <div className="container vision-container mt-110 ">
            <div className="section">
              <div className="vision_text_transition">
                <h2 className="mt-2">
                  合作伙伴 <small class="text-muted">Our partners</small>
                </h2>
                <p>
                  我们已经为以下客户提供了优质的产品及服务，我们希望与客户建立良好的、持续的伙伴关系，我们期待与您的合作。
                </p>
              </div>
            </div>
            <img src={partner_2} alt="" className="img-fluid vision_img_transition mt-3 mb-5"/>

            <div className="section">
              <div className="vision_text_transition">
                <p>
                  我们深知系统的良好运转依托每一个细节的有力执行。为了提高品质，降低客户采购成本，我们与全球最优质供应商合作，不断迭代产品、优化生产安装流程，有效降低自身成本，进而为用户创造更大价值，实现自身与用户的双赢。
                </p>
              </div>
            </div>

            <img src={partner_1} alt="" className="img-fluid vision_img_transition mt-3"/>

            <hr className="featurette-divider"></hr>
          </div>
        <Footer />
      </div>
    )
  }
}

export default Partner
