import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import Button from '../../components/button/button'
import './affiliates.css'

class Affiliates extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '相关企业'
    }
  }
  componentDidMount() {
    ScrollReveal().reveal('.members-item', {
      opacity: 0,
      scale: 0.9,
      easing: 'ease-in',
      duration: 800,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                相关企业 <small class="text-muted">affiliated enterprise</small>
              </h2>
              <p>
                投资维石与维石投资的企业，有助于扩大我们的核心竞争能力和执行能力。了解维石的相
                关企业及他们的优势、专业特长，是如何支持我们实现让整体卫浴走入每一个中国家庭的使命。
              </p>
              <p>
                现在，请跟随我们开启整体卫浴新体验。
              </p>
            </div>
            <div>
              <Button url={'/product/list'} buttonName={'我们的产品'} /> &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>

          <hr className="featurette-divider"></hr>

          <div className="section">
            <div className="vision_text_transition">
              <h4>
                广联达科技股份有限公司
              </h4>
              <p>
                广联达立足建筑产业，围绕工程项目的全生命周期，是提供以建设工程领域专业应用为核心基础支撑，
                以产业大数据、产业新金融等为增值服务的数字建筑平台服务商。
              </p>
            </div>
          </div>

          <hr className="featurette-divider"></hr>

          <div className="section">
            <div className="vision_text_transition">
              <h4>
                北新集团
              </h4>
              <p>
                作为中国新型建材工业的摇篮及世界500强成员企业，北新集团持之以恒在品牌、质量、技术、规模等方面引领中国新型建材工业发展。
              </p>
            </div>
          </div>

          <hr className="featurette-divider"></hr>

          <div className="section">
            <div className="vision_text_transition">
              <h4>
                河北永祥集成房屋有限公司
              </h4>
              <p>
                公司按照国家推进供给侧结构性改革和新型城镇化发展的要求，以卢龙县装配式建筑产业园为契机，大力发展钢结构装配式建筑，将成为冀东
                地区装配式部品部件产业链最重要的基地，将覆盖京、津及环渤海经济圈。
              </p>
            </div>
          </div>

          <hr className="featurette-divider"></hr>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Affiliates
