import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import Button from '../../components/button/button'
import './leadership.css'
import caohuijie from '../../../static/images/caohuijie.jpg';
import songyingqing from '../../../static/images/songyingqing.jpg';
import zhenghezhen from '../../../static/images/zhenghezhen.jpg';

class Leadership extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '核心团队'
    },
    leadershipList: [{
      id: 1,
      title: 'CEO及创始人',
      name: '曹祎杰'
    }, {
      id: 2,
      title: '研发总监及联合创始人',
      name: '宋英庆'
    }, {
      id: 3,
      title: '服务总监及联合创始人',
      name: '郑合阵'
    }]
  }
  componentDidMount() {
    ScrollReveal().reveal('.title-text', {
      easing: 'ease-in-out',
      duration: 800,
      delay: 500
    })
    ScrollReveal().reveal('.img-box', {
      opacity: 0,
      easing: 'ease-in',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container">
          <div className="vision-container mt-110">
            <div className="section">
              <div className="vision_text_transition">
                <h2 className="mt-2">
                  核心团队 <small className="text-muted">Leadership</small>
                </h2>
                <p>
                  我们的领导团队汇聚了行业内最好的技术、设计、制造和施工方面的人才，我们相信经验的价值，但同样不惧挑战，创新是我们不竭的动力
                </p>
              </div>
            </div>
            <div>
              <Button url={'/frontend/join/index.html'} buttonName={'加入我们'} />
            </div>
          </div>
          <div className="leader-list">
            <h4 className="">
              核心团队成员
            </h4>
            <ul className="row members_wrapper">
                {this.state.leadershipList.map(item => (
                  <li className="members-item col-sm-6 col-md-4">
                    <Link to={`/frontend/about/leaderitem/${item.id}`}>
                      <div>
                        <div className="img-box">
                          {item.id === 1 ? (<img src={caohuijie} alt="" />) : ''}
                          {item.id === 2 ? (<img src={songyingqing} alt="" />) : ''}
                          {item.id === 3 ? (<img src={zhenghezhen} alt="" />) : ''}
                        </div>
                      </div>
                      <div className="members-desc">
                        <h6>{item.name}</h6>
                        <p>{item.title}</p>
                      </div>
                    </Link>
                    <div className="spaces" />
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Leadership
