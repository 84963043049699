import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import $ from 'jquery';
import './list.css';
import point_bg from '../../../static/images/b_point_bg.jpg';
import point_bg_sp from '../../../static/images/b_point_bg_sp.jpg';
import footerBanner from '../../../static/images/footer-banner.jpg';
import product_1 from '../../../static/images/product-1.jpg';
import product_2 from '../../../static/images/product-2.jpg';
import product_3 from '../../../static/images/product-3.jpg';
import product_4 from '../../../static/images/product-4.jpg';
import product_5 from '../../../static/images/product-5.jpg';
import product_6 from '../../../static/images/product-6.jpg';
import product_7 from '../../../static/images/product-7.jpg';

import Modal from '../../components/productModal/productModal';
import ProductFilter from '../../components/productFilter/productFilter';

class List extends Component {
  state = {
    currentPage: {
      parent: '我们的产品'
    },
    productList: [{
      id: 1,
      name: '风（彩钢板型）',
      series: '风',
      tag: '家用首选',
      type: '普通住宅',
      description: '美观、耐用、清洁、舒心，用新生活美学重新定义卫浴空间'
    }, {
      id: 2,
      name: '雅（瓷砖型）',
      series: '雅',
      tag: '家用首选',
      type: '普通住宅',
      description: '均衡整体卫浴的功能性与瓷砖特有的品质感，敬献城市智识家庭'
    }, {
      id: 3,
      name: '颂（大理石型）',
      series: '颂',
      tag: '品质首选',
      type: '高级住宅',
      description: '全定制高端产品系，匹配塔尖人群生活阅历'
    }, {
      id: 4,
      name: '见微（彩钢板型）',
      series: '见微',
      tag: '公寓首选',
      type: '公寓',
      description: '为年轻而生，简约便捷的功能设计，在有限空间演绎无限精彩'
    }, {
      id: 5,
      name: '知著（彩钢板型）',
      series: '知著',
      tag: '公寓首选',
      type: '公寓',
      description: '自由流动的空间组织与复合功能的完美拼接，让想象自由自在'
    }, {
      id: 6,
      name: 'FLORA（彩钢板型）',
      series: 'FLORA',
      tag: '酒店首选',
      type: '酒店',
      description: '自由流动的空间组织与复合功能的完美拼接，让想象自由自在'
    }, {
      id: 7,
      name: '和（地胶型）',
      series: '和',
      tag: '适老首选',
      type: '适老',
      description: '为身体机能逐步退化的老人，创造独立的、有尊严的生活'
    }],
    showProductModal: false,
    index: 0,
    selectedFilter: '',
    typeId: '',
    types: [{
      id: 1,
      name: '普通住宅'
    }, {
      id: 2,
      name: '高级住宅'
    }, {
      id: 3,
      name: '公寓'
    },{
      id: 4,
      name: '酒店'
    }, {
      id: 5,
      name: '适老'
    }]
  };

  componentDidMount() {
    const width = $('body').width();
    let imageId = '';
    if (width >= 768) {
      imageId = 'point_bg';
    } else {
      imageId = 'point_bg_sp';
    }
    document.getElementById(imageId).onload = function(e){
      e.stopPropagation();
      $('.click-piece').css('display', 'block');
    }

    const typeId = window.location.href.substring(window.location.href.length - 1);
    console.log(typeId);
    this.state.types.forEach(item => {
      if (typeId == item.id) {
        this.setState({
          selectedFilter: item.name
        });
      }
    })
  }  

  filterChange = (item) => {
    if (item.name === this.state.selectedFilter) {
      this.setState({
        selectedFilter: ''
      })
    } else {
      this.setState({
        selectedFilter: item.name
      })
    }
  }

  showModal = (index) => {
    this.setState({
      showProductModal: !this.state.showProductModal,
      index
    }, () => {
      $('#productModal').modal('show');
    });
  }

  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <Modal index={this.state.index} show={this.state.showProductModal} />
        <div className="banner">
          <div className="container clearfix">
            <img src={point_bg} alt="" id="point_bg" className="img-fluid"/>
            <img src={point_bg_sp} alt="" id="point_bg_sp" className="img-fluid"/>
            <div className="click-piece" id="firstPoint" onClick={() => this.showModal(1)}>
              <div className="explain">
                <h6>1</h6>
                <p>三明治壁板技术</p>
                <span className="arrow">&gt;</span>
              </div>
            </div>
            <div className="click-piece" id="secondPoint" onClick={() => this.showModal(2)}>
              <div className="explain">
                <h6>2</h6>
                <p>底盘分层技术</p>
                <span className="arrow">&gt;</span>
              </div>
            </div>
            <div className="click-piece" id="thirdPoint" onClick={() => this.showModal(3)}>
              <div className="explain">
                <h6>3</h6>
                <p>壁挂式坐便器</p>
                <span className="arrow">&gt;</span>
              </div>
            </div>
            <div className="click-piece" id="fourthPonint" onClick={() => this.showModal(4)}>
              <div className="explain">
                <h6>4</h6>
                <p>长条形速流地漏</p>
                <span className="arrow">&gt;</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-container" id="productList">
          <div className="container">
            <div className="title text-center">
              <h3 className="jumbotron-heading">商品列表</h3>
            </div>
            <div className="row">
              <div className="col-md-9 product-list">
                <div className="row">
                  {this.state.productList.map(product =>(
                    <div className={`col-lg-6 mb-4 ${this.state.selectedFilter.length > 0 ? 'has-filter' : ''} ${this.state.selectedFilter === product.type || this.state.selectedFilter === product.series ? 'show' : ''}`} key={product.id}>
                      <div className="product">
                        <div className="pointer" onClick={() => this.props.history.push('/frontend/product/detail/index.html', { id: product.id })}  >
                          <div className="img-box">
                            { product.id === 1 ? (<img src={product_1} alt="" className="img-fluid" />) : '' }
                            { product.id === 2 ? (<img src={product_2} alt="" className="img-fluid" />) : '' }
                            { product.id === 3 ? (<img src={product_5} alt="" className="img-fluid" />) : '' }
                            { product.id === 4 ? (<img src={product_4} alt="" className="img-fluid" />) : '' }
                            { product.id === 5 ? (<img src={product_6} alt="" className="img-fluid" />) : '' }
                            { product.id === 6 ? (<img src={product_7} alt="" className="img-fluid" />) : '' }
                            { product.id === 7 ? (<img src={product_3} alt="" className="img-fluid" />) : '' }
                          </div>
                          <div className="product-detail">
                            <h5 className="product-name">{product.name} <button type="button" className="btn btn-outline-primary btn-sm" disabled>{product.tag}</button></h5>
                            <p>{product.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-3 filter-container">
                <ProductFilter selectedFilter={this.state.selectedFilter} filterChange={this.filterChange} />
              </div>
            </div>
          </div>  
        </div>       
        <div className="footer-banner">
          <img src={footerBanner} alt="footer-banner" className="img-fluid" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default List;