import React, { Component } from 'react';
import { Icon, Pagination } from 'antd';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
// import Article from '../../components/article/article';
import axios from 'axios';
import ScrollReveal from 'scrollreveal';
import './news.css';

class News extends Component {
  state = {
    currentPage: {
      parent: '新闻观点',
      child: '最近新闻'
    },
    data:[],
    loading: true,
    total: 0,
    pageSize: 6
  }

  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })

    this.loadArticleList();
  }

  loadArticleList = (pageNum = 0) => {
    axios.get(`http://39.98.146.163:8080/api/news?page=${pageNum}&size=${this.state.pageSize}`).then(response => {
      this.setState({
        data: response.data.content,
        total: response.data.totalElements,
        loading: false
      });
    });
  }

  paginationChange = (page) => {
    this.loadArticleList(page - 1);
  }

  timeFormat = (time) => {
    return time.substring(0, 10)
  }

  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                最近新闻 
              </h2>
              <p>
                在此可以看到维石最新的消息和新闻媒体的报道，持续更新敬请期待
              </p>
            </div>
          </div>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="filter">

        </div>
        <div className="container mb-4">
          <div className="row">
            {this.state.data.map(item => (
              <div className="col-lg-4 col-md-6" key={item.id}>
                <div>
                  <div className="card mb-4 article">
                    <div className="card-body">
                      <div className="img-box mb-2">
                        <img src={item.url} alt="" className="img-fluid"/>
                      </div> 
                      <div className="article-title pointer" 
                        onClick={() => this.props.history.push('/frontend/updates/detail', { id: item.id })} 
                        style={{'-webkit-box-orient': 'vertical'}}>
                        {item.title}
                      </div>
                      <p className="card-text" style={{'-webkit-box-orient': 'vertical'}}>
                        {item.titleDescribe}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <button className="btn custom-btn hvr-sweep-to-right" onClick={() => this.props.history.push('/frontend/updates/detail', { id: item.id })} buttonName={'查看'} >
                          查看
                        </button>
                        <small className="text-muted">{this.timeFormat(item.timeCreated)}</small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Article article={item}/> */}
              </div>
            ))}
            {this.state.loading ? (
            <div className="loading text-center">
              <Icon type="loading" />
              <p>加载中...</p>
            </div>) : (
              <div className="text-right" style={{width: '100%', marginRight: '15px'}}>
                <Pagination defaultCurrent={1} pageSize={this.state.pageSize} total={this.state.total} onChange={this.paginationChange}/>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default News;