import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Button from '../components/button/button';
import ScrollReveal from 'scrollreveal';
import axios from 'axios';

import './home.css';
// import video from '../../static/video/home.mp4';
import logo from '../../static/images/font-logo.png';
import footerBanner from '../../static/images/home-banner.jpg';
import product_1 from '../../static/images/product-1.jpg';
import product_2 from '../../static/images/product-2.jpg';
import product_5 from '../../static/images/product-5.jpg';
import home_1 from '../../static/images/home-1.jpg';
import home_2 from '../../static/images/home-2.jpg';
import home_3 from '../../static/images/home-3.jpg';

import image_a from '../../static/images/article-a.jpg';
import image_b from '../../static/images/article-b.jpg';
import image_c from '../../static/images/article-c.jpg';

import arrowBottom from '../../static/images/xiajiantou.png';
import arrowBottomHover from '../../static/images/xiajiantou-hover.png';


class Home extends Component {
  state = {
    news: [],
    currentPage: {},
    productList: [{
      id: 1,
      name: '风（彩钢板型）',
      series: '风',
      tag: '家用首选',
      type: '普通住宅',
      description: '美观、耐用、清洁、舒心，用新生活美学重新定义卫浴空间'
    }, {
      id: 2,
      name: '雅（瓷砖型）',
      series: '雅',
      tag: '家用首选',
      type: '普通住宅',
      description: '均衡整体卫浴的功能性与瓷砖特有的品质感，敬献城市智识家庭'
    }, {
      id: 3,
      name: '颂（大理石型）',
      series: '颂',
      tag: '品质首选',
      type: '高级住宅',
      description: '全定制高端产品系，匹配塔尖人群生活阅历'
    }]
  };

  componentDidMount() {
    ScrollReveal().reveal('.logo_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })

    this.loadArticleList();

  }

  loadArticleList = () => {
    axios.get(`http://39.98.146.163:8080/api/news/indexNews`).then(response => {
      this.setState({
        news: response.data
      });
    });
  }

  timeFormat = (time) => {
    return time.substring(0, 10)
  }

  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="home">
          <div className="screen screen-1">
            <div className="banner">
              <div className="container text-left logo_transition">
                <img src={logo} alt="logo" className="logo"/>
                <h3>全定制整体厨卫</h3>
              </div>
            </div>
            <div className="container btn-container">
              <div className="row text-center">
                <div className="col-lg-3 col-sm-6 col-12">
                  <Link to="/frontend/about/vision/index.html" className="btn hvr-sweep-to-right">关于我们</Link>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <Link to="/frontend/business/market/index.html" className="btn hvr-sweep-to-right">公司业务</Link>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <Link to="/frontend/product/list/index.html" className="btn hvr-sweep-to-right">产品目录</Link>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <Link to="/frontend/contact/index.html" className="btn hvr-sweep-to-right">联系我们</Link>
                </div>
                <div className="col-12 text-center pt-2 icon-arrow">
                  <a href="#product-container" className="btn arrow-bottom hvr-sweep-to-bottom">
                    <img src={arrowBottom} className="arrow-img" alt="" />
                    <img src={arrowBottomHover} className="arrow-img-hover" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="screen" id="product-container">
            <div className="container">
              <div className="title text-center">
                <h2 className="jumbotron-heading">我们的产品</h2>
                <p className="lead text-muted">维石整体卫浴是高科技和生活美学的交织糅合，在此关注更多我们的最新产品信息</p>
              </div>
              <div className="row product-list">
                {this.state.productList.map(product =>(
                  <div className="col-md-4 mb-3" key={product.id}>
                    <div className="pointer" onClick={() => this.props.history.push('/frontend/product/detail', { id: product.id })}>
                      <div className="product">
                        <div className="img-box">
                          { product.id === 1 ? (<img src={product_1} alt="" className="img-fluid" />) : '' }
                          { product.id === 2 ? (<img src={product_2} alt="" className="img-fluid" />) : '' }
                          { product.id === 3 ? (<img src={product_5} alt="" className="img-fluid" />) : '' }
                        </div>
                        <div className="product-detail">
                          <h5 className="product-name">{product.name} <button type="button" className="btn btn-outline-primary btn-sm" disabled>{product.tag}</button></h5>
                          <p>{product.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-12 text-right">
                  <Button url="/frontend/product/list" buttonName="更多产品" />
                </div>
              </div>
            </div>
          </div>

          <div className="screen detail">
            <div className="container">
              <div className="title text-center">
                <h2 className="jumbotron-heading">我们能为项目提供什么服务</h2>
                <p className="lead text-muted">用更简单的方法创造更舒适、洁净的水空间，了解我们的服务范围</p>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="help mb-3">
                    <div className="img-box mb-2">
                      <img src={home_2} alt="" className="img-fluid"/>
                    </div>  
                    <Link to="/" alt="" className="article-title">新建筑整体卫浴</Link>
                    <p className="card-text">
                      我们为新建商品房、保障性住房、公寓等提供端对端的整体卫浴全流程解决方案，简化流程与管理。
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="help mb-3">
                    <div className="img-box mb-2">
                      <img src={home_1} alt="" className="img-fluid"/>
                    </div>  
                    <Link to="/" alt="" className="article-title">既有建筑整体卫浴</Link>
                    <p className="card-text">
                      为既有建筑的集中改造提供解决方案、产品及服务，以最小的施工量，迅速、有效的完成整体卫浴的制造和装配。
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="help mb-3">
                    <div className="img-box mb-2">
                      <img src={home_3} alt="" className="img-fluid"/>
                    </div>  
                    <Link to="/" alt="" className="article-title">适老整体卫浴</Link>
                    <p className="card-text">
                      针对身体机能逐步退化的老人定向研发，帮助人们在逐渐步入老年的情况下，也能独立、主动的生活。
                    </p>
                  </div>
                </div>
              </div>  
            </div>
          </div>

          <div className="screen news">
            <div className="container">
              <div className="title text-center">
                <h2 className="jumbotron-heading">新闻中心</h2>
                <p className="lead text-muted">在此可以看到维石最新的消息和新闻媒体的报道，持续更新敬请期待</p>
              </div>
              <div className="row">
                {this.state.news.map(item => (
                  <div className="col-md-4" key={item.id}>
                    <div className="card mb-4 article">
                      <div className="card-body">
                        <div className="img-box mb-2">
                          {item.type === '1' ? (<img src={image_b} alt="" className="img-fluid"/>) : ''}
                          {item.type === '2' ? (<img src={image_a} alt="" className="img-fluid"/>) : ''}
                          {item.type === '3' ? (<img src={image_c} alt="" className="img-fluid"/>) : ''}
                        </div>
                        <div className="article-title pointer" 
                          onClick={() => this.props.history.push('/frontend/updates/detail', { id: item.id })} 
                          style={{'-webkit-box-orient': 'vertical'}}>
                          {item.title}
                        </div>
                        <p className="card-text" style={{'-webkit-box-orient': 'vertical'}}>
                          {item.titleDescribe}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <button className="btn custom-btn hvr-sweep-to-right" onClick={() => this.props.history.push('/frontend/updates/detail', { id: item.id })} buttonName={'查看'} >
                            查看
                          </button>
                          <small className="text-muted">{this.timeFormat(item.timeCreated)}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="screen">
            <div className="container">
              <div className="contact-title">
                <h2 className="jumbotron-heading">联系我们</h2>
              </div>
              <div className="row text-center mb-5 mt-5">
                <div className="col-lg-6">
                  <div className="contact">
                    <span className="iconfont icon-phone"></span> <span className="phone"></span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact">
                    <span className="iconfont icon-email"></span> <span className="email"></span> 
                  </div>
                </div>
              </div>
            </div>
            <img src={footerBanner} alt="footer-banner" className="img-fluid" />        
          </div>        
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;