import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Market from './market/market'
import Types from './types/types'
import ClassicCase from './classic_case/classic_case'

class Business extends Component {
  render() {
    return (
      <Switch>
        <Route path="/frontend/business/market" component={Market} />
        <Route path="/frontend/business/types" component={Types} />
        <Route path="/frontend/business/classicCase" component={ClassicCase} />
      </Switch>
    )
  }
}

export default Business
