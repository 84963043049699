import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'

class Types extends Component {
  state = {
    currentPage: {
      parent: '我们的业务',
      child: '业务模式'
    }
  }
  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
    ScrollReveal().reveal('.vision_img_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                我们的业务模式 <small className="text-muted">The business model</small>
              </h2>
            </div>
            <div className="vision_text_transition">
              <h3 className="">B2B +B2C</h3>
              <h5>1.透明</h5>
              <p>
              我们努力打造透明的商业流程，我们的价格是透明的，我们的交易系统也是透明的，
              可以清晰显示您所订购商品服务的日程计划和进展情况，透明的流程使我们能够预先发现问题并很快纠正它。
              </p>
            </div>
            <div className="vision_text_transition">
              <h5>2. 定制</h5>
              <p className="font-weight-normal">
                没有两个项目是相同的，我们用定制的理念去解决问题，我们让制造高品质的、可持续的、
                健康和耐用的定制化整体卫浴变得容易。我们将建立新的定制交付标准，帮助建筑师、工程师、建造师和业主能体验到维石整体卫浴的价值。
              </p>
            </div>
          </div>
        </div>
        
        <div className="vision-img-container">
          {/* <img src={vision_9} alt="" className="img-fluid lg vision_img_transition"/> */}
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="">B2B</h3>
              <h5>1.新建项目</h5>
              <p>
              我们以干净利落的产品和服务理念，为新建商品房、保障性住房、公寓等提供端对端的整体卫浴全流程解决方案，
              我们在整个服务过程中，努力与客户进行良好沟通和快速解决问题，使我们的客户拥有积极的体验。
              </p>
            </div>
            <div className="vision_text_transition">
              <h5>2.既有建筑改造项目</h5>
              <p className="font-weight-normal">
              维石还为既有住宅的集中改造提供解决方案、产品及服务，我们针对既有住宅非标准的空间格局，开发了柔性制造、
              闪电速装等技术，以最小的施工量，迅速、有效的完成整体卫浴的制造和装配，以减少客户的资金与时间成本。
              </p>
            </div>
            <div className="vision_text_transition">
              <h5>3.适老化项目</h5>
              <p className="font-weight-normal">
              人们越来越重视对“生活与自我”的认知，家中的“整体卫浴”可以帮助我们了解自己的身体状况、预防疾病，帮助我们在逐渐步入老年、
              身体机能退化情况下，创造独立生活的可能，保持工作能力，积极主动的生活。
              </p>
            </div>
          </div>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3 className="">B2C</h3>
              <h5>1.一对一定制化</h5>
              <p>
              现在，家庭用户同样可以享受维石整体卫浴带来的全新体验。我们针对个人家庭的空间环境，提供一对一定制化服务，
              完全贴合您的空间要求和使用习惯，创造舒适清洁的家庭水空间。
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    )
  }
}

export default Types
