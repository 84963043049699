import React, { Component } from 'react';
import './articles.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import ScrollReveal from 'scrollreveal';
import Button from '../../components/button/button'

import vision_3 from '../../../static/images/vision-3.png';
import vision_4 from '../../../static/images/vision-4.png';
import vision_4_pc from '../../../static/images/vision-4.jpg';
import vision_5 from '../../../static/images/vision-5.png';
import vision_6 from '../../../static/images/vision-6.jpg';
import vision_8 from '../../../static/images/vision-8.jpg';
import vision_9 from '../../../static/images/vision-9.png';

class Articles extends Component {
  state = {
    currentPage: {
      parent: '新闻观点',
      child: '维石理念'
    }
  }

  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
  }

  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />

        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2>维石的理念 <small class="text-muted">IDEA</small></h2>
            </div>
            <div className="vision_text_transition">
              <h3 className="">产品理念</h3>
              <h5 className="font-weight-normal">
                1.做启发生活智慧的“极致空间”
              </h5>
              <p>
                很长时间以来，卫生间一直是家中脏乱差的存在。现在，人们越来越重视这个狭小的室内空间，
                整体卫浴是解决传统卫浴中许多问题的有效方法，家中的卫生间成为使人感到安心舒适的“小憩之地“，
                从“脏乱差空间”一跃成为“极致空间”。未来，整体卫浴对我们的重要性，不仅仅在于满足需求，而是在于启发“生活智慧”。
              </p>
            </div>
            <div className="vision_text_transition">
              <h5>2. 清洁和舒适的维石整体卫浴</h5>
              <p className="font-weight-normal">
                整体卫浴是高科技和生活美学的交织糅合，清洁和舒适的卫浴空间，将会诞生一种全新的生活美学：固定在墙壁上、与地面平行的座便器，
                实现了一个足底清洁、易于清理的空间，整体卫浴代表卫浴器具的高级进化，大大提高了卫浴空间的清洁度和舒适度。
              </p>
            </div>
            <div className="vision_text_transition">
              <h5>3.适老款整体卫浴</h5>
              <p className="font-weight-normal">
              人们越来越重视对“生活与自我”的认知，家中的“整体卫浴”可以帮助我们了解自己的身体状况、预防疾病，帮助我们在逐渐步入老年、
              身体机能退化情况下，创造独立生活的可能，保持工作能力，积极主动的生活。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_3} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3>服务理念</h3>
              <h5>1. “干净利落”的帮助客户解决问题</h5>
              <p>
                “从拖泥带水到干净利索”，我们不仅将这一理念应用到整体卫浴产品本身，也应用在我们的工作流程中，能够“干净利落”的帮助客户解决问题，
                是我们存在的重要价值。因此，我们在整个服务过程中，要做到“最懂产品、最懂现场、最懂客户”，努力与客户进行良好沟通和快速解决问题，
                使我们的客户拥有积极的体验。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_8} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">   
            <div className="vision_text_transition">
              <h3>商业理念</h3>
              <h5>1. 打造“透明的”商业流程</h5>
              <p>
                我们努力打造透明的商业流程，我们的价格是透明的，我们的交易系统也是透明的，可以清晰显示您所订购商品服务的日程计划和进展情况，透明的流程使我们能够预先发现问题并很快纠正它。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_9} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container"> 
          <div className="section">
            <div className="vision_text_transition">
              <h3>研发理念</h3>
              <h5>1. 与社会保持一定距离，不满足于现状、始终报有梦想</h5>
              <p>
                凭借“与社会保持一定距离，不满足于现状、始终报有梦想”的精神，使我们保持敏锐，帮助我们总是能够不断创新、不断发现人们对居住的需求，主动挑战更好的设计和制造方法，在内装工业化领域探索与众不同的解决方案，将昨日的梦想变为明日的现实。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_6} alt="" className="img-fluid vision_img_transition"/>
        </div> 

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3>品质理念</h3>
              <h5>1. 生产和安装服务过程中的每一步都必须做对</h5>
              <p>
                从业务运营到客户服务，从产品制造到用户手册，每一处细节，我们都要坚持高标准，我们要超越客户的期望。我们的目标是成为建筑制造领域整体卫浴解决方案最佳供应商，因此我们关注每一个订单的每一个细节，成功取决于我们每一步的交付能力。
              </p>
            </div>
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_4} alt="" id="vision_4" className="img-fluid vision_img_transition"/>
          <img src={vision_4_pc} alt="" id="vision_4_pc" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container vision-container">
          <div className="section">
            <div className="vision_text_transition">
              <h3>定制</h3>
              <h5>1. 每个项目都是一次独特的机会</h5>
              <p>
                没有两个项目是相同的，我们用定制的理念去解决问题，我们让制造高品质的、可持续的、健康和耐用的定制化整体卫浴变得容易。我们将建立新的定制交付标准，帮助建筑师、工程师、建造师和业主能体验到维石整体卫浴的价值。
              </p>
            </div>
          </div>
          <div> 
            <Button url={'/business/types/index.html'} buttonName={'服务支持'} />&nbsp;&nbsp;&nbsp;&nbsp;
            <Button url={'/contact/index.html'} buttonName={'联系我们'} />
          </div>
        </div>

        <div className="vision-img-container">
          <img src={vision_5} alt="" className="img-fluid vision_img_transition"/>
        </div>

        <hr className="featurette-divider"></hr>

        <Footer />
      </div>
    );
  }
}

export default Articles;