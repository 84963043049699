import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Vision from './vision/vision.js'
import Progress from './progress/progress'
import Partner from './partner/partner'
import Factories from './factories/factories'
import Affiliates from './affiliates/affiliates'
import Leadership from './leadership/leadership'
import LeaderItem from './leadership/leader_item/leader_item'

class About extends Component {
  render() {
    return (
      <Switch>
        <Route path="/frontend/about/vision" component={Vision} />
        <Route path="/frontend/about/progress" component={Progress} />
        <Route path="/frontend/about/partner" component={Partner} />
        <Route path="/frontend/about/factories" component={Factories} />
        <Route path="/frontend/about/affiliates" component={Affiliates} />
        <Route path="/frontend/about/leadership" component={Leadership} />
        <Route path="/frontend/about/leaderitem" component={LeaderItem} />
      </Switch>
    )
  }
}

export default About
