import React, { Component } from 'react';
import PropTypes from 'prop-types'

class ProductFilter extends Component {
  static propTypes = {
    selectedFilter: PropTypes.string.isRequired,
    filterChange: PropTypes.func.isRequired
  };

  state = {
    series: [{
      id: 1,
      name: '风'
    }, {
      id: 2,
      name: '雅'
    }, {
      id: 3,
      name: '颂'
    }, {
      id: 4,
      name: '见微'
    }, {
      id: 5,
      name: '知著'
    }, {
      id: 6,
      name: 'FLORA'
    }, {
      id: 7,
      name: '和'
    }],
    types: [{
      id: 1,
      name: '普通住宅'
    }, {
      id: 2,
      name: '高级住宅'
    }, {
      id: 3,
      name: '公寓'
    },{
      id: 4,
      name: '酒店'
    }, {
      id: 5,
      name: '适老'
    }],
  }

  render() {
    return (
      <div className="col-12">
        <ul className="filter">
          <li>按产品系列</li>
          {this.state.series.map(item => (
            <li key={item.id} className={`${this.props.selectedFilter === item.name ? 'active' : ''}`} onClick={() => this.props.filterChange(item)}>{item.name}</li>
          ))}
        </ul>
        <ul className="filter">
          <li>按产品类型</li>
          {this.state.types.map(item => (
            <li key={item.id} className={`${this.props.selectedFilter === item.name ? 'active' : ''}`} onClick={() => this.props.filterChange(item)}>{item.name}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ProductFilter;