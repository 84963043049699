import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import List from './list/list';
import Detail from './detail/detail';

class Product extends Component {
  render() {
    return (
      <Switch>
        <Route path="/frontend/product/list" component={List} />
        <Route path="/frontend/product/detail" component={Detail} />
      </Switch>
    )
  }
}

export default withRouter(Product);
