import React, { Component } from 'react';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Button from '../components/button/button';
import ScrollReveal from 'scrollreveal';
import './Q&A.css';

import vision_7 from '../../static/images/vision-7.png';

class QAndA extends Component {
  state = {
    currentPage: {
      parent: 'Q&A',
      child: ''
    }
  }

  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
    ScrollReveal().reveal('.vision_img_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }

  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                维石全定制整体卫浴答客问
              </h2>
            </div>
          </div>
        </div>
        <div className="vision-img-container">
          <img src={vision_7} alt="" className="img-fluid lg vision_img_transition"/>
        </div>
        <div className="container vision-container">
          <Button url={'/frontend/contact/index.html'} buttonName={'联系我们'} /> &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className="vision_text_transition">
          <div className="issue-list">
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>1、	购买的流程是怎样的？</h5>
                    <p>
                      答：我们根据建筑原设计图来做整体卫浴的平面模数协调，在此基础上出设计图，根据设计图来做配置表，根据配置表初步报价，报价后出施工图，签订供货合同
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>2、	你们的整体卫浴的饰面有什么材料可选？</h5>
                    <p>
                      答：有SMC、彩钢板、瓷砖和大理石。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>3、	目前哪种材料用的最多？</h5>
                    <p>
                      答：国内以MSC材料为主，且多用于工装领域，日本家庭90%以上采用彩钢板整体卫浴，中高档酒店使用瓷砖或大理石饰面整体卫浴。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>4、	哪种材料的档次更高</h5>
                    <p>
                      答：根据面材不同大理石>瓷砖>彩钢板>SMC
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>5、	瓷砖和大理石饰面的墙面，是贴好了安装，还是安装后贴砖。</h5>
                    <p>
                      答：工厂贴砖，现场安装。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>6、	与室内地面会有高差吗？</h5>
                    <p>
                      答：维石整体浴室底盘厚度在7-10cm，如果没有做卫生间沉降，会有高差，后期可通过室内铺装地板、过门石等装置在视觉上消除高差
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>7、	你们只有固定规格的底盘吗？</h5>
                    <p>
                      答：维石是国内唯一拥有柔性制造技术的整体卫浴厂商，我们可以生产任意规格的产品
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>8、	门和过门石你们提供吗？</h5>
                    <p>
                      答： 门和过门石需要自行采购，我们会根据设计风格给出建议。但如果是适老整体卫浴的话，我们可以根据实际情况提供整套的门和过门石解决方案。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>9、	外墙你们处理吗？</h5>
                    <p>
                      答：外墙我们不处理。需要在整体卫浴外做墙体，一般建议轻钢龙骨，具体装饰方案和风格按照室内设计即可。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>10、	水电接口要给你们预留，还是你们来处理？</h5>
                    <p>
                      答：水电接口要预留，我们来设计水电线路，会提前做技术交底
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>11、	价格多少？</h5>
                    <p>
                      答：价格根据面积和配置来确定
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>12、	订货周期是多久？</h5>
                    <p>
                      答：从初步设计到施工图需要30个工作日左右，从订货合同签订到供货需要40个工作日左右，数量少一次性供货，数量大根据甲方施工工期分批供货
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="issue ">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h5>13、为什么要选择维石全定制整体卫浴？</h5>
                    <p>
                      （1）	整体卫浴行业标准制定者，荣获“国家重点研发计划项目科技示范工程”
                    </p>
                    <p>
                      （2） 目前国内唯一提供SMC、彩钢板、瓷砖、大理石等多种材料选择的整体卫浴厂商，满足低、中、高等不同客户需求
                    </p>
                    <p>
                      （3） 柔性制造，具有定制能力
                    </p>
                    <p>
                      （4） 快装体系，2个工人1天可完成一个整体卫浴的安装
                    </p>
                    <p>
                      （5） 从设计到施工到售后，深度配合甲方工作
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default QAndA