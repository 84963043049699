import React, { Component } from 'react';
import {
  Form, Input, Select, Button
} from 'antd';

const { Option } = Select;

class RegistrationForm extends Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        sessionStorage.completeRegistration = true;
        this.props.showDowload();
        this.props.closeModal();
      }
    });
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
  

    const formItemLayout = {
      labelCol: {
        xs: { 
          span: 24 
        },
        sm: { 
          span: 4 ,
          offset: 2,
        },
      },
      wrapperCol: {
        xs: { 
          span: 24
        },
        sm: { 
          span: 15,
          offsetRight:3
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 6,
        },
      },
    };
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item
          label="姓名">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '请输入您的姓名!', whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>
        {this.props.type === 'contact' ? '' : (<Form.Item
          label="公司">
          {getFieldDecorator('company', {
            rules: [{ required: true, message: '请输入您的公司!', whitespace: true }],
          })(
            <Input />
          )}
        </Form.Item>) }
        <Form.Item
          label="联系方式"
        >
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: '请输入您的手机号!' }],
          })(
            <Input style={{ width: '100%' }} />
          )}
        </Form.Item>
        <div>
          <button htmlType="submit" className="btn btn-primary mr-4">提交</button>
          <button className="btn btn-secondary" onClick={this.props.closeModal}>取消</button>
        </div>
      </Form>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

export default WrappedRegistrationForm;
