import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Articles from './articles/articles';
import News from './news/news';
import ArticleDetail from './detail/detail';


class Updates extends Component {

  render() {
    return (
      <Switch>
        <Route path="/frontend/updates/articles" component={Articles}/>
        <Route path="/frontend/updates/news" component={News} />
        <Route path="/frontend/updates/detail" component={ArticleDetail} />
      </Switch>
    );
  }
}

export default Updates;