import React, { Component } from 'react';
import RegisterForm from '../registerForm/registerForm';
import $ from 'jquery';

class DowloadModal extends Component {
  static propTypes = {

  };

  closeModal = () => {
    $('#dowloadModal').modal('hide');
  }

  render() {
    return (
      <div className="modal bd-example-modal-lg livison-modal" role="dialog" id="dowloadModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="title">用户注册</h5>
              <p className="instructions">注册完成后即可下载产品详情</p> 
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <RegisterForm showDowload={this.props.showDowload} closeModal={this.closeModal}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DowloadModal;