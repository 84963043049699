import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import './productModal.css';
import point1 from '../../../static/images/b_point1.png';
import point2 from '../../../static/images/b_point2.png';
import point3 from '../../../static/images/b_point3.png';
import point4 from '../../../static/images/b_point4.png';

class ProductModal extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired
  };

  closeModal = () => {
    $('#productModal').modal('hide');
  }

  render() {
    return (
      <div className="modal bd-example-modal-lg livison-modal" role="dialog" id="productModal">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
                <div className="pt_tb1">
                  <div className="pt_cl1_1">
                    <div className="pbox">
                      <p>第 {this.props.index} 点</p>
                    </div>
                  </div>
                  <div className="pt_cl1_2">
                    {this.props.index === 1 ? (<p className="pointHeading">三明治壁板技术</p>) : ''}
                    {this.props.index === 2 ? (<p className="pointHeading">底盘分层技术</p>) : ''}
                    {this.props.index === 3 ? (<p className="pointHeading">壁挂式坐便器</p>) : ''}
                    {this.props.index === 4 ? (<p className="pointHeading">长条形速流地漏</p>) : ''}
                  </div>
                </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              { this.props.index === 1 ? (<img src={point1} alt="" className="img-fluid" />) : '' }
              { this.props.index === 2 ? (<img src={point2} alt="" className="img-fluid" />) : '' }
              { this.props.index === 3 ? (<img src={point3} alt="" className="img-fluid" />) : '' }
              { this.props.index === 4 ? (<img src={point4} alt="" className="img-fluid" />) : '' }
            </div>
            <div className="modal-footer" onClick={this.closeModal}>
              <Link to="/product/detail/1">产品详情</Link> 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductModal;