import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import $ from 'jquery';
import './detail.css';
import detail_4 from '../../../static/images/detail-4.jpg';
import product_detail_1 from '../../../static/images/product_detail_1.jpg';
import product_detail_2 from '../../../static/images/product_detail_2.jpg';
import product_detail_4 from '../../../static/images/product_detail_4.jpg';
import product_detail_5 from '../../../static/images/product_detail_5.jpg';
import product_detail_6 from '../../../static/images/product_detail_6.jpg';
import product_detail_7 from '../../../static/images/product_detail_7.jpg';

import product_1_BU1623 from '../../../static/images/product_1_BU1623.jpg';
import product_2_BU1820 from '../../../static/images/product_2_BU1820.jpg';
import product_3_BU1420 from '../../../static/images/product_3_BU1420.jpg';
import product_4_BU1218 from '../../../static/images/product_4_BU1218.jpg';
import product_5_BU1420 from '../../../static/images/product_5_BU1420.jpg';
import product_6_BU1216 from '../../../static/images/product_6_BU1216.jpg';
import product_7_BU1629 from '../../../static/images/product_7_BU1629.jpg';

import pdf_1 from '../../../static/pdf/风BU1623彩钢.pdf';
import pdf_2 from '../../../static/pdf/风BU1623彩钢-开门方向.pdf';
import pdf_3 from '../../../static/pdf/雅BU1821瓷砖.pdf';
import pdf_4 from '../../../static/pdf/颂BU1420-石材壁板.pdf';
import pdf_5 from '../../../static/pdf/颂BU1420-石材壁板开门方向.pdf';
import pdf_6 from '../../../static/pdf/见微1218-彩钢公寓.pdf';
import pdf_7 from '../../../static/pdf/见微1218-彩钢公寓开门方向.pdf';
import pdf_8 from '../../../static/pdf/知著1420-彩钢公寓.pdf';
import pdf_9 from '../../../static/pdf/知著1420-彩钢公寓开门方向.pdf';
import pdf_10 from '../../../static/pdf/flora1216-彩钢公寓.pdf';
import pdf_11 from '../../../static/pdf/flora1216-彩钢公寓开门方向.pdf';
import pdf_12 from '../../../static/pdf/和1629适老公寓.pdf';

import Modal from '../../components/dowloadModal/dowloadModal';

class Detail extends Component {

  state = {
    currentPage: {
      parent: '我们的产品'
    },
    series: [{
      id: 1,
      name: '风'
    }, {
      id: 2,
      name: '雅'
    }, {
      id: 3,
      name: '颂'
    }, {
      id: 4,
      name: '见微'
    }, {
      id: 5,
      name: '知著'
    }, {
      id: 6,
      name: 'FLORA'
    }, {
      id: 7,
      name: '和'
    }],
    id: '',
    productList: [{
      id: 1,
      name: '风系列',
      tags: [{
        id: 1,
        name: '住宅'
      }, {
        id: 2,
        name: '公寓'
      }, {
        id: 3,
        name: '彩钢板'
      }, {
        id: 4,
        name: '精致型'
      }],
      models: ['BUSS 0816', 'BUSS 1114', 'BUSS 1216', 'BUSS 1218', 'BUSS 1416'],
      description: '为现代家庭打造的风系列整体卫浴，采用更为美观、耐磨的PET覆膜彩钢板，兼顾洗护、清洁、收纳等多种功能，配合柔性制造技术，充分释放每一寸空间的潜能，是极具性价比的选择。',
      features_1: '特性一：干湿分离，一体到顶钢化玻璃隔断，彻底隔绝水汽外溢',
      features_2: '特性二：湿区采用保温防滑的SMC材料，表面结构纹理设计，快干易清洁，干区铺贴瓷砖，高档美观耐磨',
      features_3: '特性三：壁挂式洗面台、马桶，足底清洁，方便打理，杜绝藏污纳垢',
      features_4: '特性四：长条形50cm深水封地漏，远离异味烦恼',
      features_5: '特性五：可视化快装技术，即装即用，零甲醛零污染'
    }, {
      id: 2,
      name: '雅系列',
      tags: [{
        id: 1,
        name: '住宅'
      }, {
        id: 2,
        name: '公寓'
      }, {
        id: 3,
        name: '瓷砖'
      }, {
        id: 4,
        name: '舒适型'
      }],
      models: ['BUSS 0816', 'BUSS 1114', 'BUSS 1216', 'BUSS 1218', 'BUSS 1416'],
      description: '雅系列产品融合整体卫浴的功能性与瓷砖的优雅质感，明快而富有变化的线条构成整个空间主体，几何拼贴在工业化生产的演绎下，一丝不苟，光亮无尘，满足人们对舒适、清洁生活的需求。',
      features_1: '特性一：瓷砖饰面，更为贴合中国家庭审美',
      features_2: '特性二：干湿分离，一体到顶钢化玻璃隔断，彻底隔绝水汽外溢，湿区采用保温防滑的SMC材料，表面结构纹理设计，快干易清洁，干区铺贴彩砖，高档美观',
      features_3: '特性三：壁挂式洗面台、马桶，足底清洁，方便打理，杜绝藏污纳垢',
      features_4: '特性四：长条形50cm深水封地漏，远离异味烦恼',
      features_5: '特性五：闪电快装，即装即用，零甲醛零污染'
    }, {
      id: 3,
      name: '颂系列',
      tags: [{
        id: 1,
        name: '别墅'
      }, {
        id: 2,
        name: '石材'
      }, {
        id: 3,
        name: '豪华型'
      }],
      models: ['BUMM 1218', 'BUMM 1416'],
      description: '尊贵、庄严、历久弥新，颂系列整体卫浴选取现代工艺切割的天然石材为饰面，将舒适享受与奢华品味完美开启，奢华但不铺张，设计的真谛被隐藏于产品细节，唯有亲自感受，才能体悟出设计者超越生活本身的表达。',
      features_1: '特性一：独家研发出天然石材饰面的整体卫浴，满足高端需求',
      features_2: '特性二：二分离／三分离设计，根据家庭结构和使用者年龄定制功能',
      features_3: '特性三：壁挂式洗面台、马桶，足底清洁，方便打理，杜绝藏污纳垢',
      features_4: '特性四：长条形50cm深水封地漏，远离异味烦恼',
      features_5: '特性五：闪电快装，即装即用，零甲醛零污染'
    }, {
      id: 4,
      name: '见微系列',
      tags: [{
        id: 1,
        name: '公寓'
      }, {
        id: 2,
        name: '酒店'
      }, {
        id: 3,
        name: '彩钢板'
      }, {
        id: 4,
        name: '紧凑型'
      }],
      models: ['BUSS 0816', 'BUSS 1114', 'BUSS 1216', 'BUSS 1218', 'BUSS 1416'],
      description: '“从细微处发觉生活的真谛”， 小小的空间，也能满足生活的各种需求，洁净明快的主色调结合材料特有的温润质感，紧凑而不失细节的功能布局，给人以舒心、愉悦的体验。',
      features_1: '特性一：three in one，淋浴、盥洗、如厕规划合理有序',
      features_2: '特性二：防水底盘一体成型，防渗防漏，20年使用无忧',
      features_3: '特性三：表面结构纹理设计，能破坏水滴张力，防止地面积水',
      features_4: '特性四：长条形50cm深水封地漏，远离异味烦恼',
      features_5: '特性五：自体保温，三九寒天无需取暖设备也能光脚淋浴',
      features_6: '特性六：闪电快装，即装即用，零甲醛零污染'
    }, {
      id: 5,
      name: '知著系列',
      tags: [{
        id: 1,
        name: '公寓'
      }, {
        id: 2,
        name: '酒店'
      }, {
        id: 3,
        name: '彩钢板'
      }, {
        id: 4,
        name: '舒适型'
      }],
      models: ['BUSS 0816', 'BUSS 1114', 'BUSS 1216', 'BUSS 1218', 'BUSS 1416'],
      description: '优秀的产品设计，离不开对生活的伟大洞察。维石“知著”系列，选用更具品质感的PET覆膜彩钢板饰面，打造出高雅、宁静的空间氛围，让卫浴空间超越盥洗功能，成为释放心灵的小憩之地',
      features_1: '特性一：盥洗、如厕、沐浴等功能模块有机组织，打造温馨舒适的卫浴空间',
      features_2: '特性二：防水底盘一体模压成型，防渗防漏，20年使用无忧',
      features_3: '特性三：表面结构纹理设计，能破坏水滴张力，防止地面积水',
      features_4: '特性四：长条形50cm深水封地漏，远离异味烦恼',
      features_5: '特性五：自体保温，三九寒天无需取暖设备也能光脚淋浴',
      features_6: '特性六：闪电快装，即装即用，零甲醛零污染'
    }, {
      id: 6,
      name: 'FLORA（芙拉尔）系列',
      tags: [{
        id: 1,
        name: '酒店'
      }, {
        id: 2,
        name: '彩钢板'
      }, {
        id: 3,
        name: '风格型'
      }],
      models: ['BUMM 1218', 'BUMM 1416'],
      description: '个性鲜明，富有想象，FLORA（芙拉尔）意为花神，设计灵感来源于希腊神话，给人清洁舒适的感受，PET覆膜彩钢板可根据酒店风格适配不同颜色、纹饰，做到风格的统一。自清洁的产品特性极大降低清扫成本。',
      features_1: '特性一：针对不同类型酒店客群，研发出功能组块模型应对不同需求',
      features_2: '特性二：防水底盘一体模压成型，防渗防漏，20年使用无忧',
      features_3: '特性三：利用底盘结构纹理，实现自体清洁，节省清洁成本',
      features_4: '特性四：长条形50cm深水封地漏，远离异味烦恼',
      features_5: '特性五：自体保温，三九寒天无需取暖设备也能光脚淋浴',
      features_6: '特性六：闪电快装，即装即用，零甲醛零污染'
    }, {
      id: 7,
      name: '和系列',
      tags: [{
        id: 1,
        name: '适老化改造'
      }, {
        id: 2,
        name: '彩钢板／SMC'
      }, {
        id: 3,
        name: '适老型'
      }],
      models: ['BUMM 1218', 'BUMM 1416'],
      description: '适老型整体卫浴针对身体机能逐步退化的老年人而研发，从地面材料选择到卫浴洁具的设计，都充分考虑到老年人的生理机能和使用场景，希望这些积极的改变能帮助老年人独立、有尊严的生活。',
      features_1: '特性一：选用进口地胶，避免坚硬，保温防滑防撞，能够快速导流防止地面积水',
      features_2: '特性二：洗手池、淋雨区都做了特殊化设计，以应对老年人的生活需要',
      features_3: '特性三：模拟真实生活场景布置支撑扶手，防止跌滑',
      features_4: '特性四：自体保温，三九寒天无需取暖设备也能光脚淋浴',
      features_5: '特性五：闪电快装，即装即用，零甲醛零污染'
    }],
    currentProduct: {},
    tags: [],
    showDowloadList: false,
    types: [{
      id: 1,
      name: '普通住宅'
    }, {
      id: 2,
      name: '高级住宅'
    }, {
      id: 3,
      name: '公寓'
    },{
      id: 4,
      name: '酒店'
    }, {
      id: 5,
      name: '适老'
    }]
  };

  componentDidMount() {
    const id = this.props.history.location.state.id
    this.state.productList.forEach(item => {
      if (id == item.id) {
        this.setState({
          currentProduct: item,
          tags: item.tags
        });
      }
    })

    if (sessionStorage.completeRegistration) {
      this.setState({
        showDowloadList: true
      });
    }
  }  

  showModal = () => {
    $('#dowloadModal').modal('show');
  }

  showDowload = () => {
    this.setState({
      showDowloadList: true
    });
  }

  productChange = (id) => {
    this.state.productList.forEach(item => {
      if (id == item.id) {
        this.setState({
          currentProduct: item,
          tags: item.tags
        });
      }
    })
    this.props.history.replace(`/frontend/product/detail/index.html`, { id });
  }

  filterChange = (item) => {
    this.props.history.replace(`/frontend/product/list/index.html`, { id: item.id });
  }

  render() {
    const id = this.state.currentProduct.id;
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <Modal showDowload={this.showDowload} />
        <div className="banner">
          <div className="container clearfix">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb detail-breadcrumb">
                <li className="breadcrumb-item"><Link to="/frontend/product/list/index.html">公司产品</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{this.state.currentProduct.name}</li>
              </ol>
            </nav>
            { id === 1 ? (<img src={product_detail_1} alt="" className="img-fluid" />) : '' }
            { id === 2 ? (<img src={product_detail_2} alt="" className="img-fluid" />) : '' }
            { id === 3 ? (<img src={product_detail_6} alt="" className="img-fluid" />) : '' }
            { id === 4 ? (<img src={product_detail_4} alt="" className="img-fluid" />) : '' }
            { id === 5 ? (<img src={product_detail_5} alt="" className="img-fluid" />) : '' }
            { id === 6 ? (<img src={detail_4} alt="" className="img-fluid" />) : '' }
            { id === 7 ? (<img src={product_detail_7} alt="" className="img-fluid" />) : '' }

            <div className="product-tag mt-2">
              {this.state.tags.map(tag => (
                <button key={tag.id} type="button" className="btn btn-primary btn-sm" disabled>{tag.name}</button>
              ))}
            </div>
          </div>  
        </div>
        <div className="product-container">
          <div className="container">
            <div className="row">
              <div className="col-md-9 ">
                <div>
                  <div className="detail-label mb-3">
                    <span>产品说明</span>
                  </div>
                  {this.state.showDowloadList ? '' : (<div className="alert alert-primary" role="alert">
                    * 点击下载按钮可下载详细产品细节
                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.showModal}>下载</button>
                  </div>)}      
                  <ul className={`list-group mb-3 dowload-list ${this.state.showDowloadList ? 'show' : '' }`}>
                    <li className="list-group-item active">下载列表：</li>
                    {id === 1 ? (<li className="list-group-item">
                      <a href={pdf_1} target="_blank" >风BU1623彩钢.pdf</a>
                    </li>) : '' }
                    {id === 1 ? (<li className="list-group-item">
                      <a href={pdf_2} target="_blank">风BU1623彩钢-开门方向.pdf</a>
                    </li>) : '' }
                    {id === 2 ? (<li className="list-group-item">
                      <a href={pdf_3} target="_blank">雅BU1821瓷砖.pdf</a>
                    </li>) : '' }
                    {id === 3 ? (<li className="list-group-item">
                      <a href={pdf_4} target="_blank">颂BU1420-石材壁板.pdf</a>
                    </li>) : '' }
                    {id === 3 ? (<li className="list-group-item">
                      <a href={pdf_5} target="_blank">颂BU1420-石材壁板开门方向.pdf</a>
                    </li>) : '' }
                    {id === 4 ? (<li className="list-group-item">
                      <a href={pdf_6} target="_blank">见微1218-彩钢公寓.pdf</a>
                    </li>) : '' }
                    {id === 4 ? (<li className="list-group-item">
                      <a href={pdf_7} target="_blank">见微1218-彩钢公寓开门方向.pdf</a>
                    </li>) : '' }
                    {id === 5 ? (<li className="list-group-item">
                      <a href={pdf_8} target="_blank">知著1420-彩钢公寓.pdf</a>
                    </li>) : '' }
                    {id === 5 ? (<li className="list-group-item">
                      <a href={pdf_9} target="_blank">知著1420-彩钢公寓开门方向.pdf</a>
                    </li>) : '' }
                    {id === 6 ? (<li className="list-group-item">
                      <a href={pdf_10} target="_blank">flora1216-彩钢公寓.pdf</a>
                    </li>) : '' }
                    {id === 6 ? (<li className="list-group-item">
                      <a href={pdf_11} target="_blank">flora1216-彩钢公寓开门方向.pdf</a>
                    </li>) : '' }
                    {id === 7 ? (<li className="list-group-item">
                      <a href={pdf_12} target="_blank">和1629适老公寓.pdf</a>   
                    </li>) : '' }
                  </ul>
                  <div className="detail-content">
                    <p>{this.state.currentProduct.description}</p>
                    <p>{this.state.currentProduct.features_1}</p>
                    <p>{this.state.currentProduct.features_2}</p>
                    <p>{this.state.currentProduct.features_3}</p>
                    <p>{this.state.currentProduct.features_4}</p>
                    <p>{this.state.currentProduct.features_5}</p>
                    <p>{this.state.currentProduct.features_6 ? this.state.currentProduct.features_6 : ''}</p>
                  </div>
                </div>
                <div>
                  <div className="detail-label mb-3">
                    <span>规格介绍</span>
                  </div>
                  <div className="detail-content mb-3">
                    { id === 1 ? (<img src={product_1_BU1623} className="img-fluid" alt="" />) : '' }
                    { id === 2 ? (<img src={product_2_BU1820} className="img-fluid" alt="" />) : '' }
                    { id === 3 ? (<img src={product_3_BU1420} className="img-fluid" alt="" />) : '' }
                    { id === 4 ? (<img src={product_4_BU1218} className="img-fluid" alt="" />) : '' }
                    { id === 5 ? (<img src={product_5_BU1420} className="img-fluid" alt="" />) : '' }
                    { id === 6 ? (<img src={product_6_BU1216} className="img-fluid" alt="" />) : '' }
                    { id === 7 ? (<img src={product_7_BU1629} className="img-fluid" alt="" />) : '' }
                  </div>
                </div>
                {/* <div>
                  <div className="detail-label mb-3">
                    <span>案例展示</span>
                  </div>
                  <div className="detail-content">
                    <div className="case">
                      <h5>北京装修案例</h5>
                      <p>北京装修案例图浴室的用水量比较大，因此装修要讲究，特别要注意细节处理，不然后期会影响到生活质量，浴室的装修要注重安全性和实用性，还要注重美观与舒适</p>
                      <img src={detail_5} className="img-fluid" alt="" />
                    </div>
                    <div className="case">
                      <h5>北京装修案例</h5>
                      <p>北京装修案例图浴室的用水量比较大，因此装修要讲究，特别要注意细节处理，不然后期会影响到生活质量，浴室的装修要注重安全性和实用性，还要注重美观与舒适</p>
                      <img src={detail_6} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-md-3 product-list">
                <div className="row">
                  <h5 className="col-12 text-center pb-2">产品列表</h5>
                  <div className="col-12">
                    <ul className="filter">
                      <li>按产品系列</li>
                      {this.state.series.map(item => (
                        <li key={item.id} className={`${id === item.id ? 'active' : ''}`} onClick={() => this.productChange(item.id)}>
                          {item.name}
                        </li>
                      ))}
                    </ul>
                    <ul className="filter">
                      <li>按产品类型</li>
                      {this.state.types.map(item => (
                        <li key={item.id} onClick={() => this.filterChange(item)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>  
                </div>
              </div>
            </div>
          </div>  
        </div>       
        <Footer />
      </div>
    );
  }
}

export default withRouter(Detail);