import React, { Component } from 'react';
import PropTypes from 'prop-types'
import './button.css';

class Button extends Component {
  static propTypes = {
    buttonName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  };

  render() {
    return (
      <a href={this.props.url} className=" btn custom-btn hvr-sweep-to-right">{this.props.buttonName}</a>
    );
  }
}

export default Button;