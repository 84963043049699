import React, { Component } from 'react';
import { Icon } from 'antd';
import axios from 'axios';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './detail.css';

class ArticleDetail extends Component {
  state = {
    currentPage: {
      parent: '新闻观点'
    },
    currentArticle: {},
    content: '',
    loading: true
  }
  
  componentDidMount() {
    const id = this.props.history.location.state.id;
    axios.get(`http://39.98.146.163:8080/api/news/${id}`).then(response => {
      this.setState({
        content: response.data.content,
        loading: false
      }); 
    });
  } 


  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
          {this.state.loading ? (
            <div className="loading text-center" style={{padding: '250px 0'}}>
              <Icon type="loading" />
              <p>加载中...</p>
            </div>) : (
            <div className="container vision-container mt-110" id="articleDetail">
              <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </div>)}
        <Footer />
      </div>
    );
  }
}

export default ArticleDetail;