import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import './factories.css'
import factories_1 from '../../../static/images/factories-1.jpg';
import factories_2 from '../../../static/images/factories-2.jpg';
import factories_3 from '../../../static/images/factories-3.jpg';
import factories_4 from '../../../static/images/factories-4.jpg';

class Factories extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '我们的工厂'
    }
  }
  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
    ScrollReveal().reveal('.vision_img_transition', {
      scale: 0.8,
      easing: 'ease-in-out',
      duration: 900,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
          <div className="container vision-container mt-110 ">
            <div className="section">
              <div className="vision_text_transition">
                <h2 className="mt-2">
                  我们的工厂 <small className="text-muted">Our Factories</small>
                </h2>
                <p>
                  维石LIVISION，是国内整体卫浴行业标准的主要参编制定者。拥有11项革新性发明，24项专利保护，解决了困扰行业多年的定制化技术难题。 秦皇岛维石“全定制整体卫浴”生产线获批“国家重点研发计划项目科技示范工程”， 2019年6月1号一期正式投产，全部建成后可实现年产7.5万套新一代整体卫浴。
                </p>
              </div>
            </div>
            <img src={factories_4} alt="" className="img-fluid vision_img_transition"/>

            <img src={factories_3} alt="" className="img-fluid vision_img_transition"/>  

            <img src={factories_1} alt="" className="img-fluid vision_img_transition mt-3"/>

            <img src={factories_2} alt="" className="img-fluid vision_img_transition mt-5"/>

            <hr className="featurette-divider"></hr>

          </div>
        <Footer />
      </div>
    )
  }
}

export default Factories
