import React, { Component } from 'react';
import { Form, Input, message } from 'antd';
import axios from 'axios';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import ScrollReveal from 'scrollreveal';
import './contact.css';

const { TextArea } = Input;

class Contact extends Component {
  state = {
    currentPage: {
      parent: '联系我们',
      child: ''
    }
  }

  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        axios.post('http://39.98.146.163:3000/users/add', values).then(response => {
          if (response.data.sendMail) {
            message.success('已成功发送邮件');
            this.props.form.resetFields();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                联系我们 <small className="text-muted">Contact</small>
              </h2>
            </div>
          </div>
        </div>

        <hr className="featurette-divider"></hr>

        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 address">
              <div className="">
                <h5>秦皇岛维石住工科技有限公司</h5>
                <p>地址 : 秦皇岛卢龙经济开发区武山路与永和大街交汇处</p>
                <p>邮箱 : sales@livision1.onaliyun.com</p>
                <p>联系电话 : 400 822 1717</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 address">
              <div className="">
                <h5>销售中心</h5>
                <p>北京维石住工科技有限公司</p>
                <p>地址 : 北京市海淀区王庄路1号清华同方科技广场D座5-B5</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container form-container">
          <h4>填写表单</h4>
          <Form layout={'vertical'} className="row contact-form" onSubmit={this.handleSubmit}>
            <div className="col-md-8 col-sm-6">
              <div className="row">
                <Form.Item label="姓名" className="col-md-6">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: '请输入您的姓名!', whitespace: true }],
                  })(
                    <Input placeholder="姓名" />
                  )}
                </Form.Item>
                <Form.Item label="公司" className="col-md-6">
                  {getFieldDecorator('company', {
                    rules: [{ required: true, message: '请输入您的公司!', whitespace: true }],
                  })(
                    <Input placeholder="公司" />
                  )}
                </Form.Item>
                <Form.Item label="职位" className="col-md-6">
                  {getFieldDecorator('position', {
                    rules: [{ required: true, message: '请输入您的职位!', whitespace: true }],
                  })(
                    <Input placeholder="职位" />
                  )}
                </Form.Item>
                <Form.Item label="邮箱" className="col-md-6">
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: '请输入您的邮箱!', whitespace: true },
                      { type: 'email', message: '请输入正确的邮箱地址！', whitespace: true }],
                  })(
                    <Input placeholder="邮箱" />
                  )}
                </Form.Item>
                <Form.Item label="联系电话" className="col-md-6">
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: '请输入您的联系电话!', whitespace: true }],
                  })(
                    <Input placeholder="联系电话" />
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <Form.Item label="留言">
                {getFieldDecorator('message', {
                  rules: [{ max: 120, message: '留言字数请限制在120字以内！', whitespace: true }],
                })(
                  <TextArea placeholder="留言" />
                )}
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item >
                <button className="btn custom-btn hvr-sweep-to-right">
                  提交
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>  
        <Footer />
      </div>
    )
  }
}


export default Form.create({ name: 'contact' })(Contact);