import React, { Component } from 'react';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import ScrollReveal from 'scrollreveal';
import './join.css';
import $ from 'jquery';
import ContactModal from '../components/contactModal/contactModal';

class Join extends Component {
  state = {
    currentPage: {
      parent: '加入维石',
      child: ''
    }
  }

  componentDidMount() {
    ScrollReveal().reveal('.vision_text_transition', {
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 400
    })
  }

  showModal = () => {
    $('#contactModal').modal('show');
  }

  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <ContactModal />
        <div className="container vision-container mt-110 ">
          <div className="section">
            <div className="vision_text_transition">
              <h2 className="mt-2">
                加入维石 <small class="text-muted">Join Livsion</small>
              </h2>
              <p>
              我们是一个富有激情，积极进取的团队，我们希望通过自己的努力能让人们的生活有所改变，我们相信相同的人总会走到一起，我们期待拥有共同理想的你加入我们，和我们一起创造更加美好的生活。
              </p>
            </div>
          </div>
        </div>

        <div className="join-container">
          <div className="container">
            <h3 className="mb-4">职位信息</h3>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="professional-info">
                  <h3>财务经理</h3>
                  <p>地点: 北京</p>
                  <button className="btn custom-btn hvr-sweep-to-right" onClick={this.showModal}>联系我们</button>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="professional-info">
                  <h3>财务主管</h3>
                  <p>地点: 广州</p>
                  <button className="btn custom-btn hvr-sweep-to-right" onClick={this.showModal}>联系我们</button>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="professional-info">
                  <h3>项目经理</h3>
                  <p>地点: 秦皇岛</p>
                  <button className="btn custom-btn hvr-sweep-to-right" onClick={this.showModal}>联系我们</button>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="professional-info">
                  <h3>内装设计师（工装方向）</h3>
                  <p>地点: 广州</p>
                  <button className="btn custom-btn hvr-sweep-to-right" onClick={this.showModal}>联系我们</button>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="professional-info">
                  <h3>企划专员</h3>
                  <p>地点: 北京</p>
                  <button className="btn custom-btn hvr-sweep-to-right" onClick={this.showModal}>联系我们</button>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="professional-info">
                  <h3>生产调度</h3>
                  <p>地点: 秦皇岛</p>
                  <button className="btn custom-btn hvr-sweep-to-right" onClick={this.showModal}>联系我们</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Footer />
      </div>
    )
  }
}

export default Join;