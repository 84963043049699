import React, { Component } from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import './progress.css'

class Progress extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '发展历程'
    }
  }
  componentDidMount() {
    ScrollReveal().reveal('.timeline-item', {
      scale: 0.9,
      easing: 'cubic-bezier(0.32, 0.8, 0.4, 0.88)',
      duration: 1100,
      delay: 500
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="timeline">
          <div className="container clearfix">
            <div className="progress-timeline clearfix">
              <ul>
                <h2>2017</h2>
                <li className="timeline-item left">
                  <time>2017年2月</time>
                  <h3>第一项专利提交申请</h3>
                </li>

                <li className="timeline-item right mt-200">
                  <time>2017年6月</time>
                  <h3>北京维石住工科技有限公司成立</h3>
                </li>

                <li className="timeline-item left mt-200">
                  <time>2017年9月</time>
                  <h3>整体卫浴柔性化生产技术突破并申请专利</h3>
                </li>

                <li className="timeline-item right mt-200">
                  <time>2017年12月</time>
                  <h3>整体浴室实现不同材质壁板连接</h3>
                </li>
             </ul>
             <ul>   
                <h2>2018</h2>
                <li className="timeline-item right">
                  <time>2018年4月</time>
                  <h3>参编《工业化住宅尺寸协调标准》颁布</h3>
                  <h3>参编《百年住宅建筑设计与评价标准》颁布</h3>
                </li>
                <li className="timeline-item left mt-200">
                  <time>2018年6月</time>
                  <h3>实现整体卫浴壁板材质多样化</h3>
                </li>

                <li className="timeline-item right">
                  <time>2018年9月</time>
                  <h3>秦皇岛维石住工科技有限公司成立，同期工厂开工建设</h3>
                </li>

                <li className="timeline-item left mt-200">
                  <time>2018年12月</time>
                  <h3>参编《装配式整体卫生间应用技术标准》颁布</h3>
                </li>
               </ul>
               <ul> 
                <h2>2019</h2>
                <li className="timeline-item right">
                  <time>2019年1月</time>
                  <h3>北京维石住工完成首轮融资</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Progress
