import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Home from '../home/home'
import Product from '../product/product'
import Business from '../business/business'
import About from '../about/about'
import Updates from '../updates/updates'
import QAndA from '../Q&A/Q&A'
import Contact from '../contact/contact'
import Join from '../join/join'
 
class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/frontend" render={() => <Redirect to="/frontend/home" />} />
        <Route path="/frontend/home" component={Home} />
        <Route path="/frontend/product" component={Product} />
        {/* <Route path="/backstage" component={Backstage} /> */}
        <Route path="/frontend/business" component={Business} />
        <Route path="/frontend/about" component={About} />
        <Route path="/frontend/updates" component={Updates} />
        <Route path="/frontend/Q&A" component={QAndA} />
        <Route path="/frontend/contact" component={Contact} />
        <Route path="/frontend/join" component={Join} />
        <Route component={Home}/>
      </Switch>
    )
  }
}

export default App
