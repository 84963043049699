import React, { Component } from 'react'
import Header from '../../../components/header/header'
import Footer from '../../../components/footer/footer'
import ScrollReveal from 'scrollreveal'
import './leader_item.css'
import caohuijie from '../../../../static/images/caohuijie.jpg';
import songyingqing from '../../../../static/images/songyingqing.jpg';
import zhenghezhen from '../../../../static/images/zhenghezhen.jpg';

class LeaderItem extends Component {
  state = {
    currentPage: {
      parent: '关于我们',
      child: '核心团队'
    },
    id: ''
  }
  componentDidMount() {
    ScrollReveal().reveal('.item_right', {
      opacity: 0,
      easing: 'ease-in',
      duration: 800,
      delay: 400
    });
    this.setState({
      id: window.location.pathname.substring(window.location.pathname.length - 1)
    })
  }
  render() {
    return (
      <div>
        <Header currentPage={this.state.currentPage} />
        <div className="leader_item_content">
          <div className="container">
              {this.state.id === '1' ? (
                <div className="row item_box">
                  <div className="item_left col-md-4 col-sm-6">
                    <div className="leader_img mb-4">
                      <img className="img-fluid" src={caohuijie} alt="" />
                    </div>
                    <div className="leader_desc">
                      <h4>曹祎杰</h4>
                      <p>CEO及创始人</p>
                    </div>
                  </div>
                  <div className="item_right col-md-8 col-sm-6">
                    <h4>简介</h4>
                    <p>
                      中国工程建设标准化协会厨卫专业委员会委员，宜居住宅产业化和绿色发展促进中心理事。参编《装配式住宅建筑设计标准》、
                      《装配式卫生间应用技术标准》等8部国内整体卫浴相关标准，作为共同作者出版《保障性住房绿色低碳技术应用和节能减排效益分析》、
                      《保障性住房卫生间标准化设计和部品体系集成》等5部“十二五国家重点图书”，参与“十三五国家重点研发项目课题研究“。
                    </p>
                    <p>
                      曾主导与日本整体卫浴企业合作，共同开发中国市场新品，建设了当时国内先进的整体卫浴生产工厂。主持国内上市企业整体卫浴
                      新公司的组建及新一代整体卫浴产品开发等工作。在多个获得中国“土木工程詹天佑奖”项目中主持整体卫浴板块的设计与建设工作。
                      专业涉及：住宅厨卫空间精细化设计、适老厨卫空间设计与产品开发、内装工业化等。
                    </p>
                  </div>
                </div>
              ) : '' }
              {this.state.id === '2' ? (<div className="row item_box">
                <div className="item_left col-md-4 col-sm-6">
                  <div className="leader_img mb-4">
                    <img className="img-fluid" src={songyingqing} alt="" />
                  </div>
                  <div className="leader_desc">
                    <h4>宋英庆</h4>
                    <p>研发总监及联合创始人</p>
                  </div>
                </div>
                <div className="item_right col-md-8 col-sm-6">
                  <h4>简介</h4>
                  <p>
                    参与并成功开发国内企业第一批面向住宅系列产品、出口日本连锁酒店产品；突破行业瓶颈，成功开发维石B2C整体卫浴产品，并获得24项国家专利。
                  </p>
                </div>
                </div>) : '' }    
              {this.state.id === '3' ? (<div className="row item_box">
                <div className="item_left col-md-4 col-sm-6">
                  <div className="leader_img mb-4">
                    <img className="img-fluid" src={zhenghezhen} alt="" />
                  </div>
                  <div className="leader_desc">
                    <h4>郑合阵</h4>
                    <p>服务总监及联合创始人</p>
                  </div>
                </div>
                <div className="item_right col-md-8 col-sm-6">
                  <h4>简介</h4>
                  <p>
                    参与管理多种类型整体卫浴产品的生产制造、生产工艺流程改进，多个大型地产项目的整体卫浴安装交付工作。参与开发维石B2C整体卫浴产品，并获得24项国家专利。
                  </p>
                </div>
              </div>) : '' }   
            </div>
          </div>
        <Footer />
      </div>
    )
  }
}

export default LeaderItem
